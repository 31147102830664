
























































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

interface VuexBindings {
  findCommission: (COMMISSION_ID: string) => ApiResource.Commission | undefined,
  findCommissionStats: (COMMISSION_ID: string) => ApiResource.CommissionStats | undefined,
  listFiles: (COMMISSION_ID: string) => ApiResource.CommissionFile[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    FileDeleteButton: () => import(/* webpackChunkName: "c-f-rm" */ '@/components/commission/FileDeleteButton.vue'),
    FileDownloadButton: () => import(/* webpackChunkName: "c-f-dl" */ '@/components/commission/FileDownloadButton.vue'),
    FileUsage: () => import(/* webpackChunkName: "c-f-u" */ '@/components/commission/FileUsage.vue'),
    MediaCommissionFile: () => import(/* webpackChunkName: "md-c-f" */ '@/components/media/CommissionFile.vue'),
  },
  data() {
    return {
      error: null as Error|null,
    };
  },
  computed: {
    ...mapGetters({
      currentUserId: 'account/Id',
      findCommission: 'commission/Find',
      findCommissionStats: 'commission/Stats',
      listFiles: 'commission/file/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    commission(): ApiResource.Commission | undefined {
      return this.findCommission(this.COMMISSION_ID);
    },
    stats(): ApiResource.CommissionStats | undefined {
      return this.findCommissionStats(this.COMMISSION_ID);
    },
    files(): ApiResource.CommissionFile[] {
      if (!this.commission) return [];
      return this.listFiles(this.COMMISSION_ID);
    },
    storageBytesLimit(): number {
      if (!this.stats) return 200000000;
      return this.stats.files.size.limit_total;
    },
    storageBytesUsed(): number {
      if (!this.stats) return 0;
      return this.stats.files.size.total;
    },
    storageFilesLimit(): number {
      if (!this.stats) return 20;
      return this.stats.files.count.limit_total;
    },
    storageFilesUsed(): number {
      if (!this.stats) return 0;
      return this.stats.files.count.total;
    },
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
