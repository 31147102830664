




































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

interface VuexBindings {
  findFile: (COMMISSION_ID: string, FILE_ID: string) => ApiResource.CommissionFile,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    FileDeleteButton: () => import(/* webpackChunkName: "c-f-rm" */ '@/components/commission/FileDeleteButton.vue'),
    FileDownloadButton: () => import(/* webpackChunkName: "c-f-dl" */ '@/components/commission/FileDownloadButton.vue'),
    MediaCommissionFile: () => import(/* webpackChunkName: "m-c-f" */ '@/components/media/CommissionFile.vue'),
    MediaCommissionItem: () => import(/* webpackChunkName: "m-c-i" */ '@/components/media/CommissionItem.vue'),
    ItemPayButton: () => import(/* webpackChunkName: "c-i-pay" */ '@/components/commission/ItemPayButton.vue'),
  },
  data() {
    return {
      error: null as Error|null,
      loading: false,
      url: null as string|null,
    };
  },
  computed: {
    ...mapGetters({
      currentUserId: 'account/Id',
      findFile: 'commission/file/Find',
      findItem: 'commission/item/Find',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    FILE_ID(): string {
      return this.$route.params.FILE_ID;
    },
    file(): ApiResource.CommissionFile | undefined {
      if (!this.COMMISSION_ID || !this.FILE_ID) return undefined;
      return this.findFile(this.FILE_ID);
    },
    isFileViewable(): boolean {
      if (!this.file) return false;

      return this.file.type.startsWith('video/')
          || this.file.type.startsWith('image/')
          || this.file.type.startsWith('audio/');
    },
    item(): ApiResource.CommissionItem | undefined {
      if (!this.COMMISSION_ID || !this.file) return undefined;
      return this.findItem(this.file.item_id);
    },
  },
  watch: {
    '$route.params.FILE_ID': {
      immediate: true,
      async handler() {
        this.fetch();
      },
    },
    'file.item_id': {
      immediate: true,
      async handler(to) {
        if (to) await this.fetchItem(to);
      },
    },
    file: {
      immediate: false,
      handler(to) {
        if (!to) this.$router.replace({ name: 'commission.files' });
      },
    },
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    async fetch() {
      await this.$store.dispatch('commission/file/Find', {
        COMMISSION_ID: this.COMMISSION_ID,
        FILE_ID: this.FILE_ID,
      }).catch(this.handleError);
    },
    async fetchItem(ITEM_ID: string) {
      await this.$store.dispatch('commission/item/Find', {
        COMMISSION_ID: this.COMMISSION_ID,
        ITEM_ID,
      }).catch(this.handleError);
    },
    async previewFile() {
      this.loading = true;

      const { data } = await this.$store.dispatch('commission/file/Download', {
        COMMISSION_ID: this.COMMISSION_ID,
        FILE_ID: this.FILE_ID,
      }).catch(this.handleError);

      if (!data) {
        this.loading = false;
        return;
      }

      this.url = data.url;
    },
  },
});
